import { apiClient } from "../infrastructure/api/apiClient";

export const register = (username: string, email: string, password: string) => {
  return apiClient.post('/auth', {
    username,
    email,
    password,
  });
};

export const checkAuth = async () => {
  console.log(apiClient);
  return await apiClient.get('/auth/me')
    .then((response)=> {
      return response;
    });
}

export const login = (username: string, password: string) => {
  return apiClient
    .post('/auth/login', {
      email: username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((e)=> console.error(e)); 
};

export const logout = () => {
  localStorage.removeItem("user");
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);

  return null;
};

export const resetPassword = async (email: string) => { 
    return await apiClient
    .post('/auth/password/reset', {
      email: email
    })
      .then((response)=> {
        return response;
      });
};

export const checkResetPasswordToken = async (resetToken: string) => { 
  return await apiClient
  .post('/auth/token/check', {
    token: resetToken
  })
    .then((response)=> {
      return response.data;
    });
};

export const setPassword = async (resetToken: string, password: string) => { 
  return await apiClient
  .put('/auth/password', {
    resetToken: resetToken,
    password: password
  })
    .then((response)=> {
      return response;
    });
};
