import {  useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import InputTextComponent from '../../components/input/InputText.component';
import { login } from '../../services/auth.service';
import './login.css';

const ErrorWrapper = styled.div`
  width: auto;
  height: 20px;
  margin-top: 20px;
  padding: 5px;
  background: #ff8f9a;
  border: 1px solid #a8323e;
`;

function LoginPage() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const handleSignIn = async () => {
        try {
            const user = await login(email, password);

            if (user.email) {
                navigate('/dashboard');
            }
        } catch(e) {
            setError(true);
        }
    }

    const handleChildStateChange = (event: any) => {
        setEmail(event.target.value);
    };

    const handleChildStateChangePassword  = (event: any) => {
        setPassword(event.target.value);
    };


    return (
        <div className="wrapper">
            <div className="leftSideWrapper">
                <div className="sideWrapper">
                    <div className="logo">
                        Magellan by Rocket Desert
                    </div>
                    <div className="description">
                        Maximizing Business Potential through Superior Onboarding Experiences
                    </div>
                </div>
            </div>
            <div className="rightSide">
                <div className="loginWrapper">
                    <div className="Texthead">
                        <h3>Login to Magellan</h3>
                    </div>
                    <div className="responsive-head">
                        <h3>Login to Magellan</h3>
                    </div>
                    <div className="form">
                        <InputTextComponent  onChildStateChange={handleChildStateChange}  label="Email" type="text" />

                        <InputTextComponent onChildStateChange={handleChildStateChangePassword}  label="Password" type="password" />

                        <div className="errorMessage">
                            {error ? <ErrorWrapper>Please use correct credentials!</ErrorWrapper> : null}
                        </div>

                        <div className="loginButton">
                            <div className="loginBtn" onClick={handleSignIn}>Sign In</div>
                        </div>


                    </div>
                </div>
            </div>
            <div className="copyright">&copy; 2023 - 2024 Rocket Desert, Inc. All rights reserved
                <a className="legalinks" href="https://rocketdesert.com/terms">Terms</a> and
                <a className="legalinks" href="https://rocketdesert.com/privacy">Privacy Policy</a>
            </div>

        </div>
    );
}

export default LoginPage;
