import * as React from 'react'
import IconTextComponent from '../components/IconText/IconText.component'
import LogoComponent from '../components/logo/Logo.component'
import './css/sidebar.css';
import { useNavigate } from 'react-router-dom';
import ProfileTitleComponent from '../components/ProfileTitle/ProfileTitle.component';
import { getCurrentUser } from '../services/auth.service'

interface Props {
    children: React.ReactNode
}
const DashboardLayout: React.FunctionComponent<Props> = (props:Props) => {
    const navigate = useNavigate();
    const user = getCurrentUser();
    const handleLink = async (route: string) => {
        navigate(route);
    }

    return <>
        <div className="wrapper">
            <div className="settings-wrapper"></div>
            <div className="sidebar-wrapper">
                <div className="sidebar-heading">
                    <LogoComponent />
                </div>
                <div className="list-group">
                    <ul className="list-items">
                        <li><IconTextComponent key="home-page" onClick={() => handleLink('/dashboard')} symbol="H"
                                               text="Home" color="#406eb8"/></li>
                    </ul>
                </div>
                <div className="divider"></div>
            </div>
            <div className="page-content-wrapper">
                <div className="header">
                    <div className="username">
                        <ProfileTitleComponent symbols={`${user.firstName[0]}${user.lastName[0]}`} />
                    </div>
                </div>
                <div className="children-pad">
                    {props.children}
                </div>
            </div>
        </div>
    </>
}

export default DashboardLayout;
