import './logo.style.css'

const LogoComponent = () => {
    return <>
        <h1>Rocket Desert</h1>
        <span className="organization">Magellan Dashboard</span>
    </>
}

export default LogoComponent;
