import './home.css';
import DashboardLayout from "../../layouts/DashboardLayout";
import styled from "styled-components";
import {useEffect, useState} from "react";
import {getOgranisationsList, updateStatus} from "../../services/ogranisation.service";
import {convertDate} from "../../utils/date";

const Header = styled.div`
    font-weight: lighter;
`;


const Table = styled.table`
    width: 100%;
    margin-top: 20px;
`;
const Tr = styled.tr`
    padding: 20px;
`;
const Td = styled.td`
    padding: 20px;
    border-bottom: 1px solid #f0f0f0
`;
const Tbody = styled.tbody``;
const THead = styled.thead``;
const Th = styled.th`
    padding: 20px;
    background-color: #1c1225;
    color: white;
`;
const ActivateButton = styled.div`
    width: 100px;
    padding: 10px;
    background: #f0f0f0;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    
`;

interface CellProps {
    value: string;
}

const Cell = styled.td<CellProps>`
  text-transform: uppercase;
  font-size: 14px; 
  padding: 10px;
  color: white;  
  background-color: ${props => props.value === 'verified' ? 'green' : 'orange'};
`;

function HomePage() {
    const [organisations, setOrganisations] = useState<any[]>([]);
    useEffect(() => {
        fetchOrganisations();
    }, []);
    const fetchOrganisations = async () => {
        const organisations = await getOgranisationsList();
        setOrganisations(organisations);
    }

    async function changeStatus(organisationId: string) {
        await updateStatus(organisationId);
        await fetchOrganisations();
    }
    return (
        <>
            <DashboardLayout>
                <Header>Home / Dashboard</Header>
                <Table>
                    <THead>
                    <Tr>
                        <Th>Organization Name</Th>
                        <Th>Verification Status</Th>
                        <Th>Plan</Th>
                        <Th>Industry</Th>
                        <Th>User Count</Th>
                        <Th>Onboarding Count</Th>
                        <Th>OffBoarding Count</Th>
                        <Th>Created At</Th>
                        <Th>Action</Th>
                    </Tr>
                    </THead>
                    <Tbody>
                        {organisations.map((organisation) => {
                            return (
                                <Tr key={organisation.id}>
                                    <Td><h3>{organisation.name}</h3></Td>
                                    <Td>
                                        <Cell value={organisation.verified}>{organisation.verified}</Cell>
                                    </Td>
                                    <Td>{organisation.plan}</Td>
                                    <Td>{organisation.industry}</Td>
                                    <Td><b>{organisation.usersCount}</b></Td>
                                    <Td><b>{organisation.onboardingCount}</b></Td>
                                    <Td><b>{organisation.offBoardingCount}</b></Td>
                                    <Td>{convertDate(organisation.createdAt)}</Td>
                                    <Td><ActivateButton onClick={() => changeStatus(organisation.id)}>Change
                                        Status</ActivateButton></Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </DashboardLayout>
        </>
    );
}

export default HomePage;
