import { Navigate } from "react-router-dom";
import useAuthorization from "./hooks/useAuthorization";
import { userRoles } from "./roles.constants";

const ProtectedRoute = ({expectedRole, children} : any) => {
    const storedData = localStorage.getItem('user');
    const isAuthorized = !!storedData;
    const areRolesRequired = !!expectedRole.length;
    const roles = [userRoles.editor];
    const rolesMatch = areRolesRequired ? expectedRole.some((r: string) => roles.indexOf(r) >= 0) : true;
    const isAuthor = useAuthorization();

    if (!isAuthor) {
        return null;
    }
    if (!isAuthorized || !rolesMatch) {
        return <Navigate to="/" replace />
    }
    return children;
}
export default ProtectedRoute;