import { apiClient } from "../infrastructure/api/apiClient";

export const getOgranisationsList = async () => {
    const response = await apiClient.get('/organization/get-all-organizations');
    return response.data;
};

export const updateStatus = (id:string) => {
    return apiClient.put(`/organization/activate/${id}`)
        .then((response)=> {
            return response.data;
        });
};