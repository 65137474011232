import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import {userRoles} from "./roles.constants";
import ProtectedRoute from "./ProtectedRoute";
import LoginPage from "./pages/login/login.page";
import HomePage from "./pages/home/home.page";

const router = createBrowserRouter([
    {
        path: "/",
        element: <LoginPage />,
    },
    {
        path: "/dashboard",
        element:
            <ProtectedRoute expectedRole={[userRoles.editor]}>
                <HomePage />
            </ProtectedRoute>,
    },
]);


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <RouterProvider router={router} />
);

reportWebVitals();
